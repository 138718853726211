import * as React from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { BuilderTrend } from "../components/svgLogos"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

function Login({ location }: PageProps): JSX.Element {
  const [url, setUrl] = React.useState<string | null>(null)
  const [mobileHeight, setMobileHeight] = React.useState<string>(`calc(100vh)`)

  React.useEffect(() => {
    setUrl(`https://buildertrend.net/NewLoginFrame.aspx?builderID=3939`)

    const updateHeight = () => {
      const height = window.innerHeight
      setMobileHeight(`calc(${height}px - 4.5rem)`)
    }

    if (typeof window !== `undefined`) {
      updateHeight()
      window.addEventListener(`resize`, updateHeight)
    }

    return () => {
      window.removeEventListener(`resize`, updateHeight)
    }
  }, [])

  return (
    <Layout>
      <SEO
        meta={{
          description: "Buildertrend login for Sundog Homes clients.",
          image: `${location.origin}/sundog-homes-logo.png`,
          imageAlt: `The Sundog Homes logo`,
        }}
        title="Client Login | Sundog Homes"
        url={location.href}
      />
      <Box
        h={{ base: mobileHeight, md: `calc(100vh - 4.5rem)` }}
        pos="relative"
        w="100vw"
      >
        <StaticImage
          alt="Lake Glenville from the porch of a Sundog-built home"
          placeholder="blurred"
          quality={50}
          src="../images/porch-on-glenville.jpg"
          style={{ height: `100%`, width: `100%` }}
        />
        <Flex
          align="center"
          bg="blackAlpha.600"
          flexDir="column"
          h="100%"
          justify="center"
          left="0px"
          mx="auto"
          pos="absolute"
          top="0px"
          w="100vw"
        >
          <Flex
            align="center"
            bg="white"
            flexDir="column"
            justify="center"
            maxW="95vw"
            pb={8}
            px={{ base: 2, sm: 4, md: 8 }}
            pt={8}
            rounded="lg"
            shadow="md"
          >
            <BuilderTrend maxW="90vw" pb={2} w="325px" />
            <Box textAlign="center" w="100%">
              <Heading fontFamily="body" fontSize="xl">
                Login to Buildertrend
              </Heading>
            </Box>
            <Box
              h={url === null ? `168px` : `auto`}
              mt={6}
              maxW="350px"
              mx="auto"
            >
              {url === null && <Box bg="gray.100" h="168px" w="300px" />}
              {url !== null && (
                <iframe src={url} style={{ height: 210, maxWidth: `90vw` }} />
              )}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Layout>
  )
}

export default Login
